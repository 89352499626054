
import { useEmailTemplateMaintenanceTracked } from "presentation/store/EmailTemplate/EmailTemplateMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import EmailTemplateMaintenance from "./EmailTemplateMaintenance";
import { EmailTemplateMaintenanceFormPanel } from "./EmailTemplateMaintenanceFormPanel";

export const EmailTemplateMasterView: React.FC = () => {
    const [emailTemplateState] = useEmailTemplateMaintenanceTracked();
    const { isSliderOpen } = emailTemplateState.masterState;

    return <>
        <div className="main-comp-wrapper">
            <SliderPanel
                isOpen={isSliderOpen}
                leftSectionWidth={isSliderOpen ? "66%" : "100%"}
                rightSectionWidth={isSliderOpen ? "34%" : "0%"}
                leftChildren={<EmailTemplateMaintenance/>}
                rightChildren={<EmailTemplateMaintenanceFormPanel/>}
            />
        </div>
    </>
}
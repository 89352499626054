
import { useEmailTemplateMaintenanceVM } from "presentation/hook/EmailTemplate/useEmailTemplateMaintenanceVM";
import { useEmailTemplateMaintenanceTracked } from "presentation/store/EmailTemplate/EmailTemplateMaintenanceProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import EmailTemplateTablePanel from "./EmailTemplateTablePanel";

const EmailTemplateMaintenance:React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [emailTemplateState] = useEmailTemplateMaintenanceTracked();
    const emailTemplateVM = useEmailTemplateMaintenanceVM();
    const {isBackMaster} = emailTemplateState;
    

    useEffect(() => {
        const initialScreen = async() => {   
            setIsLoading(true);
            try {     
                const results = await Promise.allSettled([
                    emailTemplateVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {
                        
                    } 
                })
            } catch(error) {
                setIsLoading(false);
            }
        }
        initialScreen().then(async () => {  
            emailTemplateVM.onSearch().then((data) => {            
            setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        });
            setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        });
        
    }, [isBackMaster,emailTemplateVM])


    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
    return <>
        <div className={`main-comp-wrapper`}>
            <SliderPanel
                isOpen={false}
                draggable={false}
                leftSectionWidth={"0%"}
                rightSectionWidth={"100%"}
                leftChildren={<EmailTemplateTablePanel/>}
                />
        </div>
    </>
}

export default memo(EmailTemplateMaintenance);